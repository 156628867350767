import React from "react";

function ShareIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g>
        <path
          fill="currentColor"
          d="M19.787 20c-.446.012-.84-.285-.947-.715-.874-3.44-3.834-5.96-7.39-6.295v1.054c0 .674-.407 1.282-1.033 1.543-.677.31-1.475.195-2.037-.292l-4.794-4.149C3.214 10.832 3 10.371 3 9.886c0-.486.214-.947.586-1.261L8.38 4.466c.57-.495 1.382-.606 2.066-.282.625.261 1.032.87 1.032 1.543v1.1c5.335.41 9.47 4.802 9.522 10.115-.001.79-.1 1.576-.294 2.343-.107.43-.501.727-.947.715h.028z"
          transform="matrix(-1 0 0 1 24 0)"
        />
      </g>
    </svg>
  );
}

export default ShareIcon;
