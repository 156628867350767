import React from "react";

function CommentIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g>
        <path
          d="M18.311 5.648c-3.031-3.056-7.804-3.522-11.368-1.11-3.564 2.411-4.913 7.02-3.213 10.977.086.178.114.379.08.574l-.788 3.794c-.061.296.03.602.242.816.174.173.41.267.654.26h.18l3.833-.77c.194-.024.392.004.573.08 3.95 1.702 8.553.351 10.96-3.218 2.409-3.57 1.943-8.349-1.108-11.385l-.045-.018z"
          transform="matrix(-1 0 0 1 24 0)"
        />
      </g>
    </svg>
  );
}

export default CommentIcon;
