import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  reactionBar: {
    display: "flex",
    marginTop: "16px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  left: {
    display: "flex",
    gap: "12px",
  },
  right: {
    display: "flex",
    color: "#bebec5",
    fontWeight: "bold",
    fontSize: "0.8125rem",
  },
  iconButton: {
    padding: 0,
    color: theme.mode.text.description,
  },
  icon: {
    width: 24,
    height: 24,
    fill: theme.mode.icon.light,
    color: theme.mode.icon.light,
  },
  bigIcon: {
    width: 32,
    height: 32,
  },
  iconSelected: {
    fill: theme.palette.primary.main,
  },
  iconReadonly: {
    fill: "#e4e4e4",
  },
  iconButtonLiked: {
    color: theme.palette.primary.main,
  },
  shareMenuItem: {
    color: theme.mode.text.heading,
  },
  shareMenuIcon: {
    marginRight: theme.spacing(1.5),
    marginLeft: 0,
  },
  likes: {
    cursor: "pointer",
  },
  followActionText: {
    backgroundColor: theme.mode.background.light,
    fontSize: "1.0625rem",
    fontWeight: 800,
    padding: theme.spacing(0.75, 1.5),
    margin: theme.spacing(-0.75, -1.5),
    lineHeight: "normal",
    minHeight: "auto",
    borderRadius: "9px",
    transition: "background-color 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.mode.background.default,
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "0.9375rem",
      zIndex: 10,
    },
  },
}));
