import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<
  void,
  | "mediaContainer"
  | "cardMedia"
  | "contentContainer"
  | "actionsContainer"
  | "title"
  | "subtitle"
  | "text"
  | "actionText"
>()((theme: Theme, _params, classes) => {
  const smallCardStyles = {
    mediaContainer: {
      padding: theme.spacing(2.5, 0.75, 2.5, 2.5),
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    cardMedia: {
      width: 72,
      height: 72,
      borderRadius: "12px",
    },
    contentContainer: {
      padding: theme.spacing(2.5, 2.5, 2.5, 0.75),
      "&:last-child": {
        paddingBottom: theme.spacing(2.5),
      },
    },
    actionsContainer: {
      paddingBottom: theme.spacing(1),
    },
    title: {
      fontSize: "1.0625rem",
      lineHeight: "1.4375rem",
    },
    subtitle: {
      fontSize: "0.9375rem",
      lineHeight: "1.25rem",
    },
    text: {
      fontSize: ".9375rem",
      lineHeight: "1.25rem",
      WebkitLineClamp: "2",
      display: "-webkit-box",
      overflowWrap: "anywhere",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    },
    actionText: {
      fontSize: "0.9375rem",
      position: "absolute",
      top: theme.spacing(2.5),
      right: theme.spacing(2.5),
      zIndex: 10,
    },
  } as const;

  return {
    "& *": {
      boxSizing: "border-box",
    },
    card: {
      minHeight: 196,
      minWidth: 265,
      paddingBottom: 0,
      overflow: "hidden",
      width: "100%",

      [theme.breakpoints.down("sm")]: {
        minHeight: 152,
      },
      backgroundColor: theme.mode.background.light,
      boxShadow: theme.mode.shadow.smallCard,
    },
    small: {
      minHeight: 152,
      boxShadow: "0 4px 16px 0 rgb(63 61 86 / 12%)",
      [`& .${classes.mediaContainer}`]: { ...smallCardStyles.mediaContainer },
      [`& .${classes.cardMedia}`]: { ...smallCardStyles.cardMedia },
      [`& .${classes.contentContainer}`]: { ...smallCardStyles.contentContainer },
      [`& .${classes.actionsContainer}`]: { ...smallCardStyles.actionsContainer },
      [`& .${classes.title}`]: { ...smallCardStyles.title },
      [`& .${classes.subtitle}`]: { ...smallCardStyles.subtitle },
      [`& .${classes.text}`]: { ...smallCardStyles.text },
      [`& .${classes.actionText}`]: { ...smallCardStyles.actionText },
    },
    mediaContainer: {
      boxSizing: "border-box",
      padding: theme.spacing(3),
      paddingRight: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        ...smallCardStyles.mediaContainer,
      },
    },
    cardMedia: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 148,
      height: 148,
      backgroundColor: theme.mode.background.lighter,
      borderRadius: "16px",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        ...smallCardStyles.cardMedia,
      },
      "& svg": {
        fill: theme.mode.text.heading,
        color: theme.mode.text.heading,
      },
    },
    contentContainer: {
      padding: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        ...smallCardStyles.contentContainer,
      },
    },
    description: {
      color: theme.mode.text.description,
    },
    actionsContainer: {
      paddingTop: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(0.5),
      [theme.breakpoints.down("sm")]: {
        ...smallCardStyles.actionsContainer,
      },
    },
    rightContainer: {
      minWidth: 0,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      position: "relative",
    },
    title: {
      fontSize: "1.3125rem",
      lineHeight: "1.75rem",
      color: theme.palette.primary.main,
      fontWeight: 800,
      overflow: "hidden",
      display: "-webkit-box",
      wordWrap: "break-word",
      overflowWrap: "anywhere",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: "1",
      marginTop: "0.0625rem",
      width: "fit-content",
      [theme.breakpoints.down("sm")]: {
        ...smallCardStyles.title,
      },
    },
    subtitle: {
      fontSize: "1.0625rem",
      fontWeight: 600,
      lineHeight: "1.4375rem",
      color: theme.mode.text.heading,
      marginTop: "0.125rem",
      marginBottom: "0.125rem",
      overflow: "hidden",
      display: "-webkit-box",
      wordWrap: "break-word",
      overflowWrap: "anywhere",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: "1",
      [theme.breakpoints.down("sm")]: {
        ...smallCardStyles.subtitle,
      },
    },
    clickable: {
      cursor: "pointer",
    },
    text: {
      fontWeight: "normal",
      overflow: "hidden",
      display: "-webkit-box",
      wordWrap: "break-word",
      overflowWrap: "anywhere",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: "2",
      fontSize: "1.0625rem",
      lineHeight: "1.4375rem",
      [theme.breakpoints.down("sm")]: {
        ...smallCardStyles.text,
      },
    },
    divider: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      backgroundColor: theme.palette.primary.light,
      width: 2,
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        transform: "translate(-8px)",
      },
    },
    actionText: {
      backgroundColor: theme.mode.background.light,
      fontSize: "1.0625rem",
      fontWeight: 800,
      padding: theme.spacing(0.75, 1.5),
      margin: theme.spacing(-0.75, -1.5),
      lineHeight: "normal",
      minHeight: "auto",
      borderRadius: "9px",
      transition: "background-color 0.2s ease-in-out",
      "&:hover": {
        backgroundColor: theme.mode.background.default,
      },
      [theme.breakpoints.down("xl")]: {
        ...smallCardStyles.actionText,
      },
    },
    hotTrend: {
      maxWidth: "350px",
      minHeight: "unset",
      height: "152px",
      [theme.breakpoints.down("lg")]: {
        maxWidth: "unset",
      },
    },
    moreActionIcon: {
      marginLeft: "8px",
    },
    headerIconContainer: { flex: 1 },
    cardMediaContainer: {
      display: "flex",
    },
    mediaCover: { width: "100%", height: "100%", borderRadius: 12, objectFit: "cover" },
    mediaCoverVertical: {
      borderRadius: "unset",
      maxHeight: "214px",
      objectFit: "cover",
    },
    verticalCard: {
      padding: "16px 0px",
      backgroundColor: theme.mode.background.light,
      borderRadius: "16px",
      boxShadow: "0 0 16px 0 rgba(63, 61, 86, 0.08)",
      minWidth: "275px",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "4px",
      },
    },
    verticalHeader: {
      display: "flex",
      padding: "0 12px",
      alignItems: "fkex-start",
      gap: "4px",
      justifyContent: "space-between",
    },
    headerIconWrapper: {},
    headerIconRound: {
      "& svg": {
        fill: theme.mode.text.heading,
        color: theme.mode.text.description,
      },
      backgroundColor: theme.mode.icon.activityHeader,
      width: 40,
      height: 40,
      borderRadius: "50%",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
    },
    verticalTitle: {
      fontSize: "1.0625rem",
      // color: "#3f3d56",
      color: theme.mode.text.heading,
      fontWeight: "bold",
      lineHeight: "1.18",
      overflow: "hidden",
      display: "-webkit-box",
      wordWrap: "break-word",
      overflowWrap: "anywhere",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: "2",
    },
    verticalCreatedBy: {
      fontSize: "0.6875rem",
      color: theme.mode.text.subtitle,
      fontWeight: "bold",
      letterSpacing: "0.2px",
      overflow: "hidden",
      display: "-webkit-box",
      wordWrap: "break-word",
      overflowWrap: "anywhere",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: "1",
      "& a": {
        color: theme.mode.text.heading,
        fontWeight: "800",
      },
      "& svg": {
        height: 13,
        width: 12,
        marginLeft: 4,
        position: "absolute",
        marginTop: "1.5px",
      },
    },
    verticalSubTitle: {
      fontSize: "0.6875rem",
      color: "#a9a9b2",
      lineHeight: "1.45",
    },
    headerleft: {
      display: "flex",
      alignItems: "flex-start",
      gap: "8px",
    },
    headerRight: {
      display: "flex",
    },
    verticalDescription: {
      fontSize: "0.9375rem",
      color: theme.mode.text.description,
      lineHeight: "1.33",
      padding: "0 16px",
      marginTop: "8px",
      minHeight: "45px",
      display: "flex",
      alignItems: "end",
      "& p": {
        marginTop: "unset",
      },
    },
    verticalMedia: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: theme.mode.icon.backgroundSecondary,
      marginTop: "12px",
      marginBottom: "16px",
      height: "214px",
      alignItems: "center",
      position: "relative",
      "& svg": {
        fill: theme.mode.text.heading,
        color: theme.mode.text.heading,
        width: "100%",
      },
    },
    verticalFooter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 16px",
      minHeight: "40px",
    },
    footerJoined: {
      fontSize: "0.8125rem",
      fontWeight: "bold",
      lineHeight: "1.23",
      color: "#a9a9b2",
    },
    btnSignup: {
      minWidth: "125px",
      height: "40px",
      fontWeight: "800",
      display: "inline-flex",
      fontSize: "0.8125rem",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      justifyContent: "center",
      minHeight: "unset",
    },
  };
});
