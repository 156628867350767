import i18next from "i18next";

import { UserBaseFragment, UserFragment, UserFullFragment } from "@/graphql/types";

export const getFullAuthorName = (
  author?: UserBaseFragment | UserFullFragment | UserFragment,
  anonymous?: boolean,
) => {
  if (!author || anonymous) {
    return i18next.t("Generic.Anonymous");
  }
  return author.displayName;
};
