import {
  LiveStreamCardFragment,
  LiveStreamCardSlimFragment,
  LiveStreamFragment,
  useReportLiveStreamMutation,
} from "@/graphql/types";

export const useLivestreamReportsStore = (
  liveStream: LiveStreamFragment | LiveStreamCardFragment | LiveStreamCardSlimFragment,
) => {
  const [report] = useReportLiveStreamMutation();

  return {
    report({ type, comment }: any) {
      return new Promise((resolve, reject) => {
        report({
          variables: {
            id: liveStream.id,
            type,
            comment,
          },
        }).then(({ data }) => {
          if (data?.reportLiveStream?.successful) {
            resolve(data?.reportLiveStream?.result);
          } else {
            reject(data?.reportLiveStream);
          }
        });
      });
    },
  };
};
