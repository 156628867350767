export enum TimelineRootType {
  Activity = "activity",
  Profile = "profile",
}

export enum ImageSizes {
  Original = "original",
  Thumbnail = "thumbnail",
}

export enum PostTypes {
  text = "text",
  video = "video",
  image = "image",
  media = "media",
  other = "other",
  deleted = "deleted",
}

export enum ProfileMenuOptionsKeys {
  home = "home",
  updates = "updates",
  media = "media",
  postsByOthers = "posts-by-others",
  actions = "actions",
  messages = "messages",
  peopleList = "people-lists",
}

export enum ReportActivities {
  user = "user",
  post = "post",
  reply = "reply",
  comment = "comment",
  discussionPost = "discussionPost",
  discussionReply = "discussionReply",
  discussionComment = "discussionComment",
  privateAreaPost = "privateAreaPost",
  privateAreaReply = "privateAreaReply",
  privateAreaComment = "privateAreaComment",
  initiative = "initiative",
  debate = "debate",
  petition = "petition",
  debateArgument = "debateArgument",
  genericActivity = "genericActivity",
  genericPostActivity = "genericPostActivity",
  genericCommentActivity = "genericCommentActivity",
}

export enum TranslationCategoryKeys {
  initiativeTypes = 1,
  initiativeStages = 2,
  initiativeStatus = 3,
  initiativeDeadlines = 4,
  reportProfileReasons = 6,
  genders = 7,
  initiativeInactiveReasons = 8,
  initiativeDocumentTypes = 9,
  reportInitiativeReasons = 10,
  reportPostReasons = 11,
  reportCommentReasons = 12,
  reportReplyReasons = 13,
  postTags = 14,
  InitiativeQuestionnaireAnswers = 15,
  InitiativeQuestionnaireAnswerTemplates = 16,
  genericReportReasons = 18,
}

export enum ActivityType {
  Newsfeed = "newsfeed", // this is used to retrieve all entities types
  Group = "group",
  Debate = "debate",
  Event = "event",
  Poll = "poll",
  Petition = "petition",
  Blog = "blog",
  User = "user",
  Dummy = "dummy",
}

export type YesNo = "yes" | "no";
