import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme: Theme) => ({
  card: {
    padding: theme.spacing(3),
    overflow: "hidden",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  heading: {
    display: "flex",
    alignItems: "center",
  },
  headingText: {
    margin: theme.spacing(0, 0, 0, 2),
    flexGrow: "1",
  },
  authorName: {
    fontWeight: 800,
    fontSize: "1.0625rem",
    margin: 0,
    padding: 0,
    color: theme.mode.text.heading,
  },
  streamStatus: {
    fontSize: "0.8125rem",
    color: theme.mode.text.disabled,
    margin: 0,
    padding: 0,
    fontWeight: 600,
  },
  streamTitle: {
    fontWeight: 800,
    fontSize: "1.0625rem",
    color: theme.mode.text.heading,
    margin: theme.spacing(2, 0, 0, 0),
  },
  streamDescription: {
    fontSize: "1.0625rem",
    color: theme.mode.text.description,
    margin: 0,
    flex: 1,
  },
  coverWrapper: {
    backgroundColor: theme.mode.background.lighter,
    margin: "16px -24px 0px",
    display: "flex",
    justifyContent: "center",
    height: "250px",
    alignItems: "center",
    overflow: "hidden",
    position: "relative",
  },
  cover: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  coverIcon: {
    width: "70px",
    height: "70px",
    color: theme.mode.text.heading,
    fill: theme.mode.text.heading,
  },
  statusBadge: {
    fontSize: "0.9375rem",
    fontWeight: 900,
    color: "#ffffff",
    borderRadius: "4px",
    textAlign: "center",
    display: "inline-block",
    textTransform: "uppercase",
    top: theme.spacing(3),
    right: theme.spacing(3),
    padding: theme.spacing(0.5, 2),
  },
  statusBadgeLive: {
    backgroundColor: theme.palette.primary.main,
  },
  statusBadgeScheduled: {
    backgroundColor: "#3f3d56",
  },
  statusBadgeEnded: {
    backgroundColor: "#a9a9b2",
  },
  coverStatusContainer: {
    position: "absolute",
    top: 16,
    left: 20,
  },
  liveStatusSmall: {
    position: "absolute",
    padding: theme.spacing(0.25, 1),
    fontSize: "0.75rem",
    borderRadius: "4px",
    top: "12px",
    left: "12px",
    right: "unset",
    background: theme.palette.primary.main,
  },
  reactionBar: {
    display: "flex",
    marginTop: "16px",
  },
  iconButtonLiked: {
    color: theme.palette.primary.main,
  },
  icon: {
    width: 24,
    height: 24,
    fill: theme.mode.icon.light,
    color: theme.mode.icon.light,
  },
  followButton: {
    marginLeft: "16px",
    paddingTop: "6px",
    paddingBottom: "6px",
    minHeight: "35px",
  },
}));
