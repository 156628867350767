import React from "react";

import { Box, Theme, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<void, "afterIcon">()((theme: Theme, _params, classes) => ({
  box: {
    display: "flex",
    alignItems: "center",
  },
  text: {
    textTransform: "capitalize",
    lineHeight: "1.5rem",
    fontWeight: 800,
    fontSize: "1.0625rem",
    color: theme.mode.text.heading,
    [`&.${classes.afterIcon}`]: {
      marginLeft: theme.spacing(0.5),
    },
  },
  icon: {
    width: "1.5rem",
    height: "1.5rem",
    fill: theme.mode.text.heading,
    color: theme.mode.text.heading,
    fontSize: "initial",
  },
  afterIcon: {},
}));

export type IconInfoProps = {
  text?: string;
  icon?: React.ComponentType<any>;
};

function IconInfo(props: IconInfoProps) {
  const { classes, cx } = useStyles();
  return (
    <Box className={classes.box}>
      {props.icon && <props.icon className={classes.icon} />}
      {props.text && (
        <Typography className={cx(classes.text, { [classes.afterIcon]: !!props.icon })}>
          {props.text}
        </Typography>
      )}
    </Box>
  );
}

export default IconInfo;
