import {
  LiveStreamCardFragment,
  LiveStreamCardSlimFragment,
  useLikeLiveStreamMutation,
  useUnlikeLiveStreamMutation,
} from "@/graphql/types";

export const useLiveStreamLikesStore = (
  liveStream?: LiveStreamCardFragment | LiveStreamCardSlimFragment | null,
) => {
  const [like, { loading: likeLoading }] = useLikeLiveStreamMutation();
  const [unlike, { loading: unlikeLoading }] = useUnlikeLiveStreamMutation();

  const likeLiveStream = () => {
    if (liveStream) {
      return like({
        variables: {
          id: liveStream.id,
        },
      });
    }
  };

  const unlikeLiveStream = () => {
    console.log(8.7);

    if (liveStream) {
      console.log(8);

      return unlike({
        variables: {
          id: liveStream.id,
        },
      });
    }
  };

  return {
    loading: likeLoading || unlikeLoading,
    likesCount: liveStream?.likes.likesCount || 0,
    toggleLike() {
      console.log("5");

      if (liveStream !== undefined && liveStream !== null) {
        if (liveStream.likes.liked) {
          console.log("6");

          return unlikeLiveStream();
        }
        console.log("7");

        return likeLiveStream();
      }
    },
  };
};
