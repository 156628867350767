import React, { ReactNode, MouseEvent } from "react";

import { MoreHoriz } from "@mui/icons-material";
import {
  Card,
  CardMedia,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";

import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";

import { Button } from "@/common/components/button/Button";
import Hide from "@/common/components/Hide";
import IconInfo from "@/common/components/IconInfo";
import Link from "@/common/components/Link";

import { ExtendedActivityType } from "@/graphql/types";

import VerifiedIcon from "../icons/VerifiedIcon";
import { useModalProvider } from "../providers/ModalProvider";
import { useStyles } from "./ContentCard.styles";

export type ContentCardProps = {
  title: string;
  titleLink?: string;
  followersText?: string;
  canShowFollowersList?: boolean;
  header?: string;
  headerIcon?: React.ComponentType<unknown>;
  headerIconVertical?: ReactNode;
  subtitle?: ReactNode;
  subtitleComponent?: ReactNode;
  text?: string;
  actionText?: string;
  actionDisabled?: boolean;
  actionTextUncolored?: boolean;
  media: ReactNode;
  descriptionComponent?: ReactNode;
  size?: "small" | "normal";
  hotTrend?: boolean;
  onAction?: () => void;
  onBookmark?: () => void;
  actionMenu?: { label: string; onClick: () => void }[];
  actionComponent?: ReactNode;
  dataCy?: string;
  cardType?: "normal" | "vertical";
  createdByIsVerified?: boolean;
  createdByLabel?: string;
  createdByLink?: string;
  cardActionsMenu?: ReactNode;
  hideMenu?: boolean;
  activityId?: string;
  activityType?: ExtendedActivityType;
  numberFollowers?: number;
};

function ContentCard({ cardType = "normal", ...props }: ContentCardProps) {
  const { classes, cx } = useStyles();
  const { open } = useModalProvider();

  const handleActionButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    props.onAction?.call(null);
  };

  const menuState = usePopupState({ variant: "popover", popupId: "cardMenu" });
  const handleMenuClick = (f: () => void) => () => {
    f();
    menuState.close();
  };

  const Description = (
    <>
      <Hide if={!props.descriptionComponent}>{props.descriptionComponent}</Hide>
      <Hide if={!!props.descriptionComponent || !props.text}>
        <p className={classes.text}>{props.text}</p>
      </Hide>
    </>
  );
  const DescriptionVertical = (
    <>
      <Hide if={!props.text}>
        <p className={classes.text}>
          {props.titleLink ? (
            <Link href={props.titleLink}>
              <a>{props.text}</a>
            </Link>
          ) : (
            props.text
          )}
        </p>
      </Hide>
    </>
  );

  const handleFollowersClick = () => {
    if (props.canShowFollowersList && props.activityId && props.activityType) {
      open(props.activityId, props.activityType, props.numberFollowers || 0);
    }
  };

  return cardType == "normal" ? (
    <Card
      className={cx(
        classes.card,
        props.size === "small" && classes.small,
        props.hotTrend && classes.hotTrend,
      )}
      elevation={3}
      data-cy={props.dataCy}>
      <Grid container direction="column">
        <Grid item container wrap="nowrap">
          <Grid item classes={{ root: classes.cardMediaContainer }}>
            <div className={classes.mediaContainer}>
              <Link href={props.titleLink || ""} passHref>
                <CardMedia className={classes.cardMedia}>{props.media}</CardMedia>
              </Link>
            </div>
          </Grid>
          <Grid item className={classes.rightContainer}>
            <CardContent classes={{ root: classes.contentContainer }}>
              <Grid container spacing={2} wrap="nowrap">
                <Grid item xs classes={{ root: classes.headerIconContainer }}>
                  <IconInfo text={props.header} icon={props.headerIcon}></IconInfo>
                </Grid>
                <Grid item container width="auto">
                  {props.actionText && (
                    <Button
                      onClick={handleActionButtonClick}
                      className={classes.actionText}
                      variant={props.actionTextUncolored ? "inline-secondary" : "inline-primary"}
                      disabled={props.actionDisabled}>
                      {props.actionText}
                    </Button>
                  )}
                  {props.actionComponent}
                  {props.actionMenu && props.actionMenu.length > 0 && (
                    <>
                      <IconButton
                        size="small"
                        classes={{ root: classes.moreActionIcon }}
                        {...bindTrigger(menuState)}
                        data-cy="card-more-actions-button">
                        <MoreHoriz />
                      </IconButton>
                      <Menu
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        {...bindMenu(menuState)}>
                        {props.actionMenu.map(({ label, onClick }) => (
                          <MenuItem onClick={handleMenuClick(onClick)} key={label}>
                            {label}
                          </MenuItem>
                        ))}
                      </Menu>
                    </>
                  )}
                </Grid>
              </Grid>

              <Tooltip title={props.title} placement="top" arrow>
                <Typography variant="h4" className={classes.title}>
                  {props.titleLink ? (
                    <Link href={props.titleLink}>
                      <a>{props.title}</a>
                    </Link>
                  ) : (
                    props.title
                  )}
                </Typography>
              </Tooltip>

              <Hide if={!props.subtitle}>
                <Typography variant="h6" className={classes.subtitle}>
                  {props.subtitle}
                </Typography>
              </Hide>

              <Hide if={!props.subtitleComponent}>{props.subtitleComponent}</Hide>

              <div className={classes.description}>{Description}</div>
            </CardContent>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  ) : (
    <div className={classes.verticalCard}>
      <div className={classes.verticalHeader}>
        <div className={classes.headerleft}>
          <div className={classes.headerIconWrapper}>
            <div className={classes.headerIconRound}>{props.headerIconVertical}</div>
          </div>
          <div>
            <div className={classes.verticalTitle}>
              {props.titleLink ? (
                <Link href={props.titleLink}>
                  <a>{props.title}</a>
                </Link>
              ) : (
                props.title
              )}
            </div>

            {props.createdByLabel && (
              <div className={classes.verticalCreatedBy}>
                <span>Created by&nbsp;</span>
                <Link href={props.createdByLink || ""}>
                  <a>{props.createdByLabel}</a>
                </Link>
                {props.createdByIsVerified && <VerifiedIcon />}
              </div>
            )}
          </div>
        </div>
        <div className={classes.headerRight}>
          {!!props.hideMenu && props.cardActionsMenu}
          {props.actionMenu && props.actionMenu.length > 0 && (
            <>
              <IconButton
                size="small"
                classes={{ root: classes.moreActionIcon }}
                {...bindTrigger(menuState)}
                data-cy="card-more-actions-button">
                <MoreHoriz />
              </IconButton>
              <Menu
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                {...bindMenu(menuState)}>
                {props.actionMenu.map(({ label, onClick }) => (
                  <MenuItem onClick={handleMenuClick(onClick)} key={label}>
                    {label}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </div>
      </div>
      <div className={classes.verticalDescription}>{DescriptionVertical}</div>

      {props.titleLink ? (
        <Link href={props.titleLink}>
          <a>
            <div className={classes.verticalMedia}>{props.media}</div>
          </a>
        </Link>
      ) : (
        <div className={classes.verticalMedia}>{props.media}</div>
      )}

      <div className={classes.verticalFooter}>
        <div
          className={cx(classes.footerJoined, { [classes.clickable]: props.canShowFollowersList })}
          onClick={() => handleFollowersClick()}>
          {props.followersText}
        </div>
        <div>
          {props.actionText && (
            <Button
              color="primary"
              onClick={handleActionButtonClick}
              className={classes.btnSignup}
              variant={props.actionTextUncolored ? "secondary" : "primary"}
              disabled={props.actionDisabled}>
              {props.actionText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContentCard;
