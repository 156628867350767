import React, { useState, ReactNode, useEffect, useRef } from "react";

import { Theme, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";

type StylesHookProps = {
  gradientColor?: string;
  collapsedHeight: number;
};
const useStyles = makeStyles<StylesHookProps, "collapsed">()(
  (theme: Theme, { gradientColor, collapsedHeight }, classes) => ({
    container: {
      position: "relative",
      display: "block",
      height: "auto",
      overflow: "unset",

      [`&.${classes.collapsed}`]: {
        overflow: "hidden",
        height: `${collapsedHeight}px`,
        "&:after": {
          content: "''",
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          height: 0,
          color: gradientColor,
          boxShadow: theme.spacing(0, 0, 3, 3),
        },
      },
    },
    collapsed: {},
    footer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    toggle: {
      color: theme.palette.primary.main,
      fontSize: "0.9375rem",
      fontWeight: "bold",
      cursor: "pointer",
    },
  }),
);

type ContentCollapseProps = {
  children: ReactNode;
  collapsedHeight?: number;
  expandText?: string;
  collapseText?: string;
  gradientColor?: string;
};

const ContentCollapse = ({
  children,
  collapsedHeight = 100,
  expandText,
  collapseText,
  gradientColor,
}: ContentCollapseProps) => {
  const theme = useTheme();
  const { classes, cx } = useStyles({
    gradientColor: gradientColor || theme.mode.background.light,
    collapsedHeight,
  });
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [height, setHeight] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const collapsible = height > collapsedHeight;

  const handleContainerModified = () => {
    setHeight(containerRef.current?.clientHeight || 0);
  };

  const handleToggle = () => {
    setCollapsed(!collapsed);
    if (!collapsed && wrapperRef.current) {
      const { top, bottom } = wrapperRef.current.getBoundingClientRect();
      const isInViewport =
        top >= 0 && bottom <= (window.innerHeight || document.documentElement.clientHeight);
      if (!isInViewport) {
        wrapperRef.current.scrollIntoView({ block: "start" });
        window.scrollBy(0, -200);
      }
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("DOMSubtreeModified", handleContainerModified);
      setShowContent(true);

      return () =>
        containerRef.current?.removeEventListener("DOMSubtreeModified", handleContainerModified);
    }
  }, [children]);

  return (
    <>
      <div
        ref={wrapperRef}
        className={cx(classes.container, { [classes.collapsed]: collapsible && collapsed })}>
        <div ref={containerRef}>{showContent && children}</div>
      </div>
      {collapsible && (
        <div className={classes.footer}>
          <div onClick={handleToggle} className={classes.toggle}>
            {collapsed ? expandText || t("Generic.ReadMore") : collapseText || t("Generic.SeeLess")}
          </div>
        </div>
      )}
    </>
  );
};

export default ContentCollapse;
