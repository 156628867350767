import dynamic from "next/dynamic";
import { useRouter } from "next/router";

import { MoreHoriz } from "@mui/icons-material";
import { CircularProgress, IconButton, Menu, MenuItem } from "@mui/material";

import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { useTranslation } from "react-i18next";

import { ConfirmDialogProps } from "@/common/components/ConfirmDialog/ConfirmDialog";
import { useStyles } from "@/common/components/ContentCard.styles";
import type { InvitePeopleDialogProps } from "@/common/components/InvitePeopleDialog";
import type { ReportAbuseDialogProps } from "@/common/components/ReportAbuseDialog";

import { useAuthenticationStore } from "@/authentication/hooks";
import { bindDialogState, useDialog } from "@/common/hooks";
import { useConfirmDialog } from "@/common/hooks/use-confirm-dialog";

import { SITE_URL } from "@/common/constants";
import { ReportActivities } from "@/common/models/Enums";
import { ReportReason } from "@/common/models/Report";
import { useSnackbarContext } from "@/common/providers";
import { copyToClipboard } from "@/common/services";
import {
  Invitable,
  LiveStreamCardFragment,
  LiveStreamCardSlimFragment,
  ExtendedActivityType,
} from "@/graphql/types";

import { useLiveStreamFollowersStore } from "../hooks/use-livestream-followers-store";
import { useLivestreamReportsStore } from "../hooks/use-livestream-reports-store";
import { useLiveStreamStore } from "../hooks/use-livestream-store";

const InvitePeopleDialog = dynamic<InvitePeopleDialogProps>(
  () =>
    import("@/common/components/InvitePeopleDialog").then((module) => module.InvitePeopleDialog),
  {
    ssr: false,
  },
);
const ReportAbuseDialog = dynamic<ReportAbuseDialogProps>(
  () => import("@/common/components/ReportAbuseDialog").then((module) => module.ReportAbuseDialog),
  {
    ssr: false,
  },
);
const ConfirmDialog = dynamic<ConfirmDialogProps>(
  () =>
    import("@/common/components/ConfirmDialog/ConfirmDialog").then(
      (module) => module.ConfirmDialog,
    ),
  {
    ssr: false,
  },
);

type LivestreamCardActionsProps = {
  livestream: LiveStreamCardFragment | LiveStreamCardSlimFragment;
  isOwner: boolean;
};

const LivestreamCardActions = ({ livestream, isOwner }: LivestreamCardActionsProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const router = useRouter();

  const { deleteLiveStream, deleteLoading } = useLiveStreamStore({ id: livestream.id });

  const { report } = useLivestreamReportsStore(livestream);
  const { isUserLoggedIn } = useAuthenticationStore();
  const dialogState = useDialog<ReportReason>();
  const invitePeopleDialogState = useDialog();
  const { invitePeople } = useLiveStreamFollowersStore(livestream);
  const isReportingAvailable = isUserLoggedIn && !isOwner;
  const isDeleteAvailable = isUserLoggedIn && isOwner;
  const menuState = usePopupState({ variant: "popover", popupId: "cardMenu" });
  const handleReport = () => {
    dialogState.open();
    menuState.close();
  };
  const confirmDeleteState = useConfirmDialog();

  const { open: openSnackbar } = useSnackbarContext();

  const handleCopyUrl = () => {
    copyToClipboard(`${SITE_URL}${livestream.url}/`);
    menuState.close();
    openSnackbar({
      open: true,
      message: t("App.Activity.UrlCopied"),
      delay: 3500,
    });
  };

  const handleInvite = () => {
    invitePeopleDialogState.open();
    menuState.close();
  };

  // const handleFollow = () => {
  //   toggleFollow();
  //   menuState.close();
  // };

  const handleDelete = () => {
    confirmDeleteState.open({
      title: t("LiveStreams.Delete"),
      message: t("LiveStreams.DeleteConfirmation"),
      onConfirm: async () => {
        await deleteLiveStream();
        menuState.close();
      },
    });
  };

  return (
    <>
      <div>
        <ConfirmDialog dialogState={confirmDeleteState} />

        <IconButton
          size="small"
          classes={{ root: classes.moreActionIcon }}
          {...bindTrigger(menuState)}
          data-cy="card-more-actions-button">
          <MoreHoriz />
        </IconButton>
        <Menu
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          {...bindMenu(menuState)}>
          {/* {isUserLoggedIn && (
            <MenuItem onClick={() => handleFollow()}>
              {livestream.follows.isFollowedByMe
                ? t("Discover.UnfollowText")
                : t("Discover.FollowText")}
            </MenuItem>
          )} */}
          {isUserLoggedIn && (
            <MenuItem onClick={() => handleInvite()}>{t("Common.Actions.Invite")}</MenuItem>
          )}
          <MenuItem onClick={() => handleCopyUrl()}>{t("Common.Actions.CopyUrl")}</MenuItem>
          {isReportingAvailable && (
            <MenuItem onClick={() => handleReport()}>{t("LiveStreams.Actions.Report")}</MenuItem>
          )}
          {isDeleteAvailable && (
            <MenuItem
              disabled={deleteLoading}
              style={{ color: "#ff3333" }}
              onClick={() => handleDelete()}>
              {deleteLoading ? (
                <>
                  {t("Timeline.DeleteLivestream")}
                  &nbsp;&nbsp;
                  <CircularProgress size={18} color="inherit" />
                </>
              ) : (
                t("Timeline.DeleteLivestream")
              )}
            </MenuItem>
          )}
        </Menu>
        <ReportAbuseDialog
          {...bindDialogState(dialogState)}
          type={ReportActivities.genericActivity}
          onReport={report}
          user={livestream.author}
          activityId={livestream.id}
          activityTitle={livestream.title}
          activityType={ExtendedActivityType.LiveStream}
          activityFollowedByMe={livestream.follows.isFollowedByMe}
        />
        <InvitePeopleDialog
          {...bindDialogState(invitePeopleDialogState)}
          onInvite={invitePeople}
          invitableType={Invitable.LiveStream}
          invitableId={livestream.id}
        />
      </div>
    </>
  );
};

export default LivestreamCardActions;
