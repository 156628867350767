import { useRouter } from "next/router";
import React, { useState } from "react";

import { Card } from "@mui/material";

import { useTranslation } from "react-i18next";

import { Button } from "@/common/components/button/Button";
import ContentCard, { ContentCardProps } from "@/common/components/ContentCard";
import ContentCollapse from "@/common/components/ContentCollapse";
import Link from "@/common/components/Link";
import UserAvatar from "@/common/components/UserAvatar";

import VideoIcon from "@/common/icons/VideoIcon";

import { useAuthenticationStore } from "@/authentication/hooks";
import { useLiveStreamFollowersStore } from "@/liveStreams/hooks/use-livestream-followers-store";

import { USERS_REGISTER_URL } from "@/common/constants";
import { getRelativeDate, getVideoPosterFromPlaybackId } from "@/common/services";
import { getFullAuthorName } from "@/common/services/fullName";
import {
  ExtendedActivityType,
  LiveStreamCardFragment,
  LiveStreamCardSlimFragment,
  UserBaseFragment,
} from "@/graphql/types";

import { useStyles } from "./LiveStreamCard.styles";
import LiveStreamReactionBar from "./LiveStreamReactionBar";

type LiveStreamCardProps =
  | ({
      liveStream: LiveStreamCardFragment | LiveStreamCardSlimFragment;
      small?: never;
      inCommunityFeed?: boolean;
      showFollowButton?: boolean;
    } & Partial<ContentCardProps>)
  | ({
      liveStream: LiveStreamCardFragment | LiveStreamCardSlimFragment;
      inCommunityFeed?: boolean;
      small: true;
      showFollowButton?: boolean;
    } & Partial<ContentCardProps>);

const LiveStreamCard = ({
  liveStream,
  small,
  cardType,
  inCommunityFeed,
  showFollowButton,
  ...props
}: LiveStreamCardProps) => {
  const { toggleFollow } = useLiveStreamFollowersStore(liveStream);

  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const { isUserLoggedIn, session } = useAuthenticationStore();
  const router = useRouter();

  const hasParentGroup = !!liveStream.group?.url;

  const [showCover, setShowCover] = useState(true);

  const handleActionClick = () => {
    if (!isUserLoggedIn) {
      router.push(USERS_REGISTER_URL);
      return;
    }
    if (liveStream.author?.isMe) {
      router.push(`/livestreams/${liveStream.slug}/edit`);
      return;
    }

    toggleFollow();
  };
  const getCoverImageUrl = (): string | undefined => {
    if (liveStream.cover?.url) {
      return liveStream.cover?.url;
    }
    if (liveStream.status !== "active") {
      return getVideoPosterFromPlaybackId(
        (liveStream.assets.length > 0 && liveStream.assets[0].playbackId) || undefined,
      );
    } else {
      return getVideoPosterFromPlaybackId(liveStream.playbackId || undefined);
    }
  };

  const handleRestrictedAction = () => {
    router.push({ pathname: USERS_REGISTER_URL, query: { referralPath: router.asPath } });
  };

  const status =
    liveStream.status === "active"
      ? t("LiveStreams.LiveNow")
      : t("LiveStreams.LiveAt", {
          time: getRelativeDate(new Date(liveStream.startAt)),
        });

  const statusToBadge = {
    active: t("LiveStreams.ItemTypes.LIVE"),
    idle: t("LiveStreams.ItemTypes.SCHEDULED"),
    disabled: t("LiveStreams.ItemTypes.ENDED"),
  };
  const count = liveStream.followersCount;
  const followersText =
    count === 1
      ? t("Common.follower_count", { count })
      : t("Common.follower_count_plural", { count });
  const getActionText = () => {
    if (session?.user?.id === liveStream.author?.id) {
      return t("Timeline.EditLivestream");
    }
    return liveStream.follows.isFollowedByMe ? t("Generic.Unfollow") : t("Generic.Follow");
  };
  const cover =
    showCover && getCoverImageUrl() ? (
      <img
        src={getCoverImageUrl()}
        alt="Livestream"
        className={classes.cover}
        onError={() => setShowCover(false)}
      />
    ) : (
      <VideoIcon className={classes.coverIcon} />
    );

  if (small) {
    return (
      <ContentCard
        header={t("Common.livestream")}
        title={liveStream.title}
        text={liveStream.description || ""}
        subtitle={
          liveStream.author
            ? `${t("Generic.CreatedBy")} ${getFullAuthorName(
                liveStream.author as UserBaseFragment,
              )}`
            : undefined
        }
        actionTextUncolored={
          liveStream.follows.isFollowedByMe && session?.user?.id !== liveStream.author?.id
        }
        actionText={getActionText()}
        onAction={handleActionClick}
        titleLink={liveStream.url}
        headerIconVertical={<VideoIcon color="#3f3d56" />}
        media={
          <>
            {liveStream.status === "active" && (
              <span className={cx(classes.statusBadge, classes.liveStatusSmall)}>
                {t("LiveStreams.Live")}
              </span>
            )}
            {cover}
          </>
        }
        cardType={cardType}
        createdByLabel={hasParentGroup ? liveStream.group?.title : liveStream.author?.displayName}
        createdByLink={hasParentGroup ? liveStream.group?.url : liveStream.author?.url}
        createdByIsVerified={!hasParentGroup && liveStream.author?.verified}
        followersText={followersText}
        canShowFollowersList={count > 0}
        activityId={liveStream.id}
        activityType={ExtendedActivityType.LiveStream}
        numberFollowers={count}
        {...props}
      />
    );
  }

  return (
    <Card className={classes.card} elevation={3}>
      <div className={classes.heading}>
        <Link href={liveStream.author?.url || ""}>
          <a>
            <UserAvatar user={liveStream.author} />
          </a>
        </Link>
        <div className={classes.headingText}>
          <Link href={(hasParentGroup ? liveStream.group?.url : liveStream.author?.url) || ""}>
            <a>
              <p className={classes.authorName}>
                {hasParentGroup
                  ? liveStream.group?.title
                  : getFullAuthorName(liveStream.author as UserBaseFragment)}
              </p>
            </a>
          </Link>
          <p className={classes.streamStatus}>{status}</p>
        </div>
        {showFollowButton && !liveStream.author?.isMe && (
          <div>
            <Button
              className={classes.followButton}
              onClick={handleActionClick}
              size="medium"
              variant={liveStream.follows.isFollowedByMe ? "secondary" : "primary"}>
              {liveStream.follows.isFollowedByMe ? t("Generic.Unfollow") : t("Generic.Follow")}
            </Button>
          </div>
        )}
        {showFollowButton && liveStream.author?.isMe && (
          <div>
            <Button
              className={classes.followButton}
              onClick={handleActionClick}
              size="medium"
              variant="primary">
              {t("Timeline.EditLivestream")}
            </Button>
          </div>
        )}
      </div>
      <Link href={liveStream.url}>
        <a>
          <h3 className={classes.streamTitle}>{liveStream.title}</h3>
        </a>
      </Link>
      <p className={classes.streamDescription}>
        <ContentCollapse collapsedHeight={82}>{liveStream.description}</ContentCollapse>
      </p>
      <Link href={liveStream.url}>
        <a className={classes.coverWrapper}>
          <div className={classes.coverStatusContainer}>
            {liveStream.status in statusToBadge && (
              <span
                className={cx(classes.statusBadge, {
                  [classes.statusBadgeLive]: liveStream.status === "active",
                  [classes.statusBadgeScheduled]: liveStream.status === "idle",
                  [classes.statusBadgeEnded]: liveStream.status === "disabled",
                })}>
                {/* @ts-ignore */}
                {statusToBadge[liveStream.status]}
              </span>
            )}
          </div>
          <span>{cover}</span>
        </a>
      </Link>
      <LiveStreamReactionBar
        liveStream={liveStream}
        isReadonly={!isUserLoggedIn}
        isRestricted={!isUserLoggedIn}
        inCommunityFeed={inCommunityFeed}
        // TODO in future
        // onFollowableInteraction={}
        onReadonlyInteraction={handleRestrictedAction}
        onRestrictedAction={handleRestrictedAction}
      />
    </Card>
  );
};

export default LiveStreamCard;
