import { useEffect } from "react";

import {
  LiveStreamInput,
  LiveStreamChangedDocument,
  RootSubscriptionType,
  useDeleteLiveStreamMutation,
  useGetLiveStreamQuery,
  useUpdateLiveStreamMutation,
  useEndLiveStreamMutation,
} from "@/graphql/types";

type SlugOrID = { slug: string; id?: never } | { slug?: never; id: string };

export const useLiveStreamStore = ({ slug, id }: SlugOrID, runSubscribtion?: boolean) => {
  const {
    data,
    loading: liveStreamLoading,
    error: liveStreamError,
    subscribeToMore,
  } = useGetLiveStreamQuery({ variables: { slug, id }, fetchPolicy: "cache-and-network" });

  const [deleteLiveStream, { loading: deleteLoading, error: deleteError }] =
    useDeleteLiveStreamMutation({
      refetchQueries: ["GetLiveStreams", "GetUserLiveStreams"],
    });
  const [updateLiveStream, { loading: updateLoading, error: updateError }] =
    useUpdateLiveStreamMutation({
      refetchQueries: ["GetLiveStreams", "GetLiveStream"],
    });
  const [endLiveStream, { loading: endLoading, error: endError }] = useEndLiveStreamMutation({
    refetchQueries: ["GetLiveStreams", "GetLiveStream"],
  });

  useEffect(() => {
    let unsubscribe: () => void | undefined;
    if (runSubscribtion && data?.liveStream?.id) {
      unsubscribe = subscribeToMore({
        document: LiveStreamChangedDocument,
        variables: { id: data.liveStream.id },
        // @ts-ignore
        updateQuery: (prev, { subscriptionData }) => {
          const liveStream = (subscriptionData.data as RootSubscriptionType)?.liveStreamChanged;

          if (!liveStream || liveStream.id !== prev.liveStream?.id) return prev;

          return {
            ...prev,
            liveStream: {
              ...prev.liveStream,
              ...liveStream,
            },
          };
        },
      });
    }

    return () => {
      unsubscribe?.call(null);
    };
  }, [runSubscribtion, subscribeToMore, data?.liveStream?.id]);

  return {
    liveStream: data?.liveStream,
    liveStreamLoading,
    liveStreamError,
    deleteLiveStream: async () =>
      data?.liveStream?.id
        ? await deleteLiveStream({ variables: { id: data?.liveStream?.id } })
        : null,
    deleteLoading,
    deleteError,
    updateLiveStream: async (input: LiveStreamInput) =>
      data?.liveStream?.id
        ? await updateLiveStream({ variables: { id: data?.liveStream?.id, data: input } })
        : null,
    updateLoading,
    updateError,
    endLiveStream: async () =>
      data?.liveStream?.id ? await endLiveStream({ variables: { id: data.liveStream.id } }) : null,
    endLoading,
    endError,
  };
};
