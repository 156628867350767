import React, { HTMLAttributes } from "react";

function NewMessageIcon(props: HTMLAttributes<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M11.056 3a1.444 1.444 0 0 1 0 2.889l-5.018-.007c-.094.016-.16.1-.148.244l-.002 11.797a.26.26 0 0 0 .069.188l12.006.007c.093-.016.159-.1.147-.244v-4.93a1.444 1.444 0 1 1 2.889 0v5.115a3.05 3.05 0 0 1-2.937 2.94H5.938a3.046 3.046 0 0 1-2.937-3.125l.001-11.933A3.054 3.054 0 0 1 5.957 3zm6.528.726a.983.983 0 0 1 1.293.033l1.332 1.313a.91.91 0 0 1 .034 1.299l-7.597 7.673a.978.978 0 0 1-.588.273l-2.027.183h-.044a.49.49 0 0 1-.345-.14.475.475 0 0 1-.141-.383l.209-1.998a.953.953 0 0 1 .277-.58z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default NewMessageIcon;
