import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React from "react";

import { Facebook, Twitter } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";

import { usePopupState, bindMenu, bindTrigger } from "material-ui-popup-state/hooks";
import { useTranslation } from "react-i18next";
import { Subject } from "rxjs";

import type { ChatRoomShareDialogProps } from "@/chat/components/ChatRoomShareDialog/ChatRoomShareDialog";
import Link from "@/common/components/Link";

import LinkIcon from "@/common/icons/about/LinkIcon";
import ChatIcon from "@/common/icons/ChatIcon";
import CommentIcon from "@/common/icons/CommentIcon";
import HeartIcon from "@/common/icons/HeartIcon";
import NewMessageIcon from "@/common/icons/NewMessageIcon";
import ShareIcon from "@/common/icons/ShareIcon";

import { useAuthenticationStore } from "@/authentication/hooks";
import { useChatsStore } from "@/chat/hooks";
import { bindDialogState, useDialog, useUnsubscribe } from "@/common/hooks";
import { useLiveStreamLikesStore } from "@/liveStreams/hooks/use-livestream-likes-store";

import { SITE_URL } from "@/common/constants";
import { useSnackbarContext } from "@/common/providers";
import { copyToClipboard } from "@/common/services";
import {
  ChatInput,
  LiveStreamCardFragment,
  LiveStreamCardSlimFragment,
  useSendMessageMutation,
} from "@/graphql/types";
import { useStyles } from "@/liveStreams/styles/LiveStreamReactionBar.styles";
import { getFacebookIntent, getTwitterIntent, openShareWindow } from "@/posts/services/share";

const ChatRoomShareDialog = dynamic<ChatRoomShareDialogProps>(
  () =>
    import("@/chat/components/ChatRoomShareDialog/ChatRoomShareDialog").then(
      (module) => module.ChatRoomShareDialog,
    ),
  {
    ssr: false,
  },
);

const unsubscribe = new Subject();

// TODO: To be used in the near future
// const FollowUserButton = ({ user }: { user: UserListEntity }) => {
//   const { t } = useTranslation();
//   const { classes } = useStyles();
//   const { toggleFollow, loadingToggleFollow } = useProfileFollowersStore({
//     user: user as UserBaseFragment,
//   });

//   if (user.isMe) {
//     return null;
//   }

//   return (
//     <Button
//       className={classes.followActionText}
//       variant={user.follows?.isFollowedByMe ? "inline-secondary" : "inline-primary"}
//       onClick={toggleFollow}
//       disabled={loadingToggleFollow}>
//       {loadingToggleFollow ? (
//         <Spinner variant="small" />
//       ) : (
//         <span>
//           {user.follows?.isFollowedByMe ? t("Discover.UnfollowText") : t("Discover.FollowText")}
//         </span>
//       )}
//     </Button>
//   );
// };

interface LiveStreamReactionBarProps {
  liveStream: LiveStreamCardFragment | LiveStreamCardSlimFragment;
  isReadonly?: boolean;
  inCommunityFeed?: boolean;
  onFollowableInteraction?: () => void;
  refresh?: number;
  showAdditionalInfo?: boolean;
  initialCommentsLimit?: number;
  highlightedCommentId?: string;
  highlightedCommentReplyId?: string;
  timelinePinnable?: boolean;
  inViewport?: boolean;
  isRestricted?: boolean;
  onRestrictedAction?: () => void;
  onReadonlyInteraction?: () => void;
}

function LiveStreamReactionBar({
  liveStream,
  isReadonly,
  isRestricted,
  inCommunityFeed,
  onRestrictedAction,
  onFollowableInteraction,
  onReadonlyInteraction,
}: LiveStreamReactionBarProps) {
  const { toggleLike, loading } = useLiveStreamLikesStore(liveStream);

  const router = useRouter();
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  useUnsubscribe(unsubscribe);
  // const userListDialogState = useDialog();
  const sharePopupState = usePopupState({ variant: "popover", popupId: "postMenu" });
  const { session } = useAuthenticationStore();
  const { open: openSnackbar } = useSnackbarContext();
  const ChatRoomShareDialogState = useDialog();
  const { createChat } = useChatsStore();
  const [sendMessage, { loading: loadingSendMessage }] = useSendMessageMutation();

  const fullActivityURL = SITE_URL + liveStream.url;

  const handleLikeClick = () => {
    if (isReadonly) return onReadonlyInteraction && onReadonlyInteraction();
    if (loading) return;
    onFollowableInteraction && onFollowableInteraction();
    toggleLike();
  };

  const handleRestrictedAction = () => {
    onRestrictedAction && onRestrictedAction();
  };

  const handleLikesCountClick = () => {
    // TODO
  };

  const handleCommentsIconClick = () => {
    router.push(liveStream.url);
  };

  const handleCopyURL = () => {
    copyToClipboard(fullActivityURL);
    sharePopupState.close();
    openSnackbar({
      open: true,
      message: t("Common.SharePost.CopyLinkSuccess"),
    });
  };

  const handleChatShare = () => {
    sharePopupState.close();
    ChatRoomShareDialogState.open();
  };

  const handleChatShareSend = async (data: ChatInput) => {
    if (data) {
      const response = await createChat(data);
      if (response.data?.createChat?.id) {
        await sendMessage({
          variables: {
            data: {
              chatId: response.data?.createChat?.id,
              body: `<a href="${decodeURIComponent(
                fullActivityURL,
              )}" rel="noopener noreferrer" target="_blank">${decodeURIComponent(
                fullActivityURL,
              )}</a>`,
            },
          },
        });
        ChatRoomShareDialogState.close();
      }
    }
  };

  const handleProfileShare = () => {
    if (session?.user) {
      router.push({
        pathname: session?.user?.url,
        query: {
          popup: "write-post",
          text: t("Common.SharePost.Message.Profile", {
            author:
              liveStream.author && "username" in liveStream.author
                ? liveStream.author?.username
                : "",
            url: fullActivityURL,
          }) as string,
        },
      });
    }
    sharePopupState.close();
  };

  const handleFacebookShare = () => {
    openShareWindow(getFacebookIntent(fullActivityURL));
    sharePopupState.close();
  };

  const handleTwitterShare = () => {
    openShareWindow(getTwitterIntent(t("Common.SharePost.Message.Twitter"), fullActivityURL));
    sharePopupState.close();
  };
  const commentLabel = t(
    `Timeline.${(liveStream.commentsNumber || 0) > 1 ? "Comments" : "Comment"}`,
  ) as string;

  const shareItems = [
    {
      action: handleCopyURL,
      label: t("Common.Actions.CopyUrl"),
      IconComponent: LinkIcon,
    },
    {
      action: isRestricted ? handleRestrictedAction : handleChatShare,
      label: t("Common.SharePost.ShareOnChat"),
      IconComponent: ChatIcon,
    },
    {
      action: isRestricted ? handleRestrictedAction : handleProfileShare,
      label: t("Common.SharePost.ShareOnProfile"),
      IconComponent: NewMessageIcon,
    },
    {
      action: handleFacebookShare,
      label: t("Common.SharePost.ShareOnFacebook"),
      IconComponent: Facebook,
    },
    {
      action: handleTwitterShare,
      label: t("Common.SharePost.ShareOnTwitter"),
      IconComponent: Twitter,
    },
  ];

  return (
    <div className={classes.reactionBar}>
      <div className={classes.left}>
        <div>
          <IconButton
            classes={{
              root: cx(classes.iconButton, liveStream.likes.liked && classes.iconButtonLiked),
            }}
            size="small"
            color={liveStream.likes.liked ? "primary" : "default"}
            onClick={isRestricted ? handleRestrictedAction : handleLikeClick}>
            <HeartIcon
              className={cx(classes.icon, {
                [classes.iconSelected]: liveStream.likes.liked,
                [classes.iconReadonly]: isReadonly,
              })}
            />
          </IconButton>
        </div>
        <div>
          <IconButton
            classes={{
              root: classes.iconButton,
            }}
            size="small"
            color="default"
            onClick={handleCommentsIconClick}>
            <CommentIcon className={cx(classes.icon, { [classes.bigIcon]: inCommunityFeed })} />
          </IconButton>
        </div>
        <div>
          <IconButton
            classes={{ root: classes.iconButton }}
            size="small"
            {...bindTrigger(sharePopupState)}>
            <ShareIcon
              className={cx(classes.icon, {
                [classes.iconReadonly]: isReadonly,
                [classes.bigIcon]: inCommunityFeed,
              })}
            />
          </IconButton>

          <Menu
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            {...bindMenu(sharePopupState)}>
            {shareItems.map(({ action, label, IconComponent }, i) => (
              <MenuItem key={i} className={classes.shareMenuItem} onClick={action}>
                <IconComponent
                  className={cx(classes.icon, classes.shareMenuIcon, {
                    [classes.bigIcon]: inCommunityFeed,
                  })}
                />
                {label}
              </MenuItem>
            ))}
          </Menu>

          <ChatRoomShareDialog
            {...bindDialogState(ChatRoomShareDialogState)}
            onSubmit={handleChatShareSend}
            loading={loadingSendMessage}
          />
        </div>
      </div>
      <div className={classes.right}>
        {liveStream.likes.likesCount > 0 && (
          <div className={classes.likes} onClick={handleLikesCountClick}>
            {liveStream.likes.likesCount}&nbsp;
            {t(`Timeline.${liveStream.likes.likesCount > 1 ? "Likes" : "Like"}`) as string}
          </div>
        )}
        {/* <UserListModal
          open={userListDialogState.internalState.open}
          onClose={() => userListDialogState.close()}
          title={t("Timeline.Likes")}
          users={followers}
          headerLeftAction={<></>}
          hasMoreUsers={hasMorePages}
          loadingUsers={loading}
          fetchMoreUsers={fetchNextFollowersPage}
          UserAction={FollowUserButton}
        /> */}
        {liveStream.likes.likesCount > 0 && liveStream.commentsNumber > 0 && (
          <div>
            <span>&nbsp;&ndash;&nbsp;</span>
          </div>
        )}
        {liveStream.commentsNumber > 0 && (
          <div>
            <Link href={liveStream.url}>
              <a>
                {liveStream.commentsNumber}&nbsp;{commentLabel}
              </a>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default LiveStreamReactionBar;
