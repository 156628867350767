export const getTwitterIntent = (text: string, url: string) => {
  return `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    text,
  )}&url=${encodeURIComponent(url)}`;
};

export const getFacebookIntent = (url: string) => {
  return `https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`;
};

export const openShareWindow = (url: string) => {
  window.open(url, "_blank", "popup=true,height=600,width=600");
};
